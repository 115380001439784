import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { pageTitle } from '../../helper'
import Button from '../Button'
import Cta from '../Cta'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

import { Base64 } from 'js-base64';
//-----------MUI--------------------------
import {Button as MUIButton, Link, Tab, Box, Dialog, Card, CardMedia, CardContent, Typography, CardActions} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
//-------------Gallery---------------------
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import PageHeadingEvents from '../PageHeadingEvents';



// Define a custom styled component for the responsive image container
const ResponsiveImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: '56.25%', // 16:9 aspect ratio (change this value to match your image's aspect ratio)
  marginBottom: theme.spacing(2),
}));

// Define a custom styled component for the image itself
const ResponsiveImage = styled('img')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '80%',
  objectFit: 'cover',
  borderRadius: '15px',
});

export default function EventDetailsPage() {


  let encodedID = useParams().id;
  let id = Base64.decode(encodedID)

  //--------------------Dialog---------------------------
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  //-------------------States----------------------------

  const [eventDetails, setEventDetails] = useState(null);
  const [images, setImages] = useState(null);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  

  pageTitle('Event Details');
  useEffect(() => {
    fetch(`https://mhangsa.glitch.me/api/events/${id}`)
    .then(response => response.json())
    .then(data => {
      if (data && data.images && data.images.length > 0) {
        const newImages = data.images.slice(1).map((image) => ({
          original: image,
          thumbnail: image,
        }));
        setImages(newImages);
      } else {
        setImages([]);
      }
      setEventDetails(data);
    })
    .catch(error => console.error(error));
    window.scrollTo(0, 0)
  }, [])
  

  return (
    <>

  {eventDetails && eventDetails.popup === true &&   
      <Dialog open={open} onClose={handleClose}>
      <Card  sx={{background: '#212529', color: 'white'}}>
      <CardMedia
        component="img"
        sx={{background: '#212529', color: 'white', height: 320}}
        image={eventDetails && eventDetails.popupImage}
        title={eventDetails && eventDetails.title}
      />
      <CardContent sx={{background: '#212529', color: 'white'}}>
        <Typography gutterBottom variant="h5" component="div">
        {eventDetails && eventDetails.title}
        </Typography>
       
      </CardContent>
      <CardActions sx={{background: '#212529', color: 'white', justifyContent: 'center', mb: 1}}>
         {eventDetails && eventDetails.status === 'Upcoming' &&  <MUIButton variant="contained" color="error" sx={{mr: 1}} style={{background: '#bc1b2c', color: 'white'}} component={Link} href={eventDetails.form} >Register Now</MUIButton>}
        <MUIButton variant="outlined" color="error" onClick={handleClose}>Close</MUIButton>
      </CardActions>
    </Card>
      </Dialog>
}


    {eventDetails === null ? <> 
    <PageHeadingEvents 
        title='Loading...'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText="Loading..."
      />
      </> : 
    <>
      <PageHeadingEvents 
        title={eventDetails.title}
        bgSrc='/images/service_hero_bg.jpeg'
        videoSrc={eventDetails.video}
        pageLinkText={eventDetails.title}
      />
      <Spacing lg='150' md='80'/>


      <Div className="container">
      <ResponsiveImageContainer>
        <ResponsiveImage src={eventDetails.banner} alt="Banner" />
      </ResponsiveImageContainer>
      
        <Spacing lg='90' md='40'/>

        <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value} >
                <Box sx={{ mb: 2 }}>
                  <TabList onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "#bc1b2c" } }}>
                    <Tab label="Details" value="1" style={{ color: value === "1" ? "#bc1b2c" : "#b9b9b9" }}/>
                   {eventDetails.images.length > 0 && (
                      <Tab label="Gallery" value="2"style={{color: value === "2" ? "#bc1b2c" : "#b9b9b9"}}/>
                    )}
                  </TabList>
                </Box>
                <TabPanel value="1">

              <Div className="row">
                <Div className="col-lg-6">
                  <SectionHeading 
                    title={eventDetails.title}
                    subtitle={eventDetails.category}
                  >
                    <Spacing lg='40' md='20'/>
                    {eventDetails.description}
                  </SectionHeading>

                </Div>
                <Div className="col-lg-5 offset-lg-1">
                  <Spacing lg='60' md='40'/>
                  <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Event Info - </h2>
                  <Spacing lg='50' md='30'/>
                  <Div className="row">
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                      <p className='cs-m0'>{eventDetails.category}</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Location:</h3>
                      <p className='cs-m0'>{eventDetails.location}</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Status:</h3>
                      <p className='cs-m0' style={{textTransform: 'capitalize'}}>{eventDetails.status}</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Dated:</h3>
                      <p className='cs-m0'>{eventDetails.date}</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>{eventDetails.status === 'Upcoming' ? <>Expected:</> : <>Audience:</>}</h3>
                      <p className='cs-m0'>{eventDetails.audience}+ people</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-6">
                      <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Prize pool:</h3>
                      <p className='cs-m0'>Rs {eventDetails.prize_pool}</p>
                      <Spacing lg='30' md='30'/>
                    </Div>
                  </Div>
                </Div>
              </Div>
              </TabPanel>

              <TabPanel value="2"><ImageGallery items={images} showBullets={true} /></TabPanel>
              </TabContext>
            </Box>

        <Spacing lg='65' md='10'/>

        <Div className="cs-center">
          {eventDetails.status === 'Upcoming' ? 
          <>
          <MUIButton variant="contained" style={{background: '#bc1b2c', color: 'white'}} component={Link} href={eventDetails.form} >Register for {eventDetails.title}</MUIButton>
          </>
          : null}
      </Div>

      <Spacing lg='65' md='10'/>

          <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink='' btnText='Prev Event' variant='cs-type1'/>
            </Div>
            <Div>
              <Button btnLink='' btnText='Next Event'/>
            </Div>
          </Div>
      </Div>
      <Spacing lg='145' md='80'/>
      <Cta 
        title='mhangsa.creations@gmail.com' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
      </>
      }
    </>
  )
}
