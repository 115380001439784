import { Route, Routes } from 'react-router-dom';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import EventDetailsPage from './components/Pages/EventDetailsPage';
import Layout from './components/Layout';
import Events from './components/Pages/Events';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="events/:id"
            element={<EventDetailsPage />}
          />
          <Route path="events" element={<Events/>} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
         
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
