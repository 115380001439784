import React from 'react'
import { Link } from '@mui/material';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link href='https://www.facebook.com/MhangsaCreationOfficial' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} className="cs-center">
        <Icon icon="gg:facebook" />
      </Link>
      <Link href='https://www.instagram.com/mhangsa.creations/' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} className="cs-center">
        <Icon icon="mdi:instagram" />               
      </Link>
      <Link href='https://www.youtube.com/@mhangsa8028' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </Link>
    </Div>
  )
}
