import React, {useState, useEffect} from 'react'
import Slider from "react-slick";
import Div from '../Div';
import Post from '../Post';

import { Base64 } from 'js-base64';

export default function PostSlider() {

  const [pastEvents, setPastEvents] = useState([]);


  
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows:false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };



  useEffect(() => {
    fetch('https://mhangsa.glitch.me/api/events')
      .then(response => response.json())
      .then(data => {
        const pastEvents = data.filter(event => event.status === 'Completed');
        setPastEvents(pastEvents.reverse());
      })
      .catch(error => console.error(error));

  }, []);



  return (
    <Slider {...settings} className='cs-gap-24'>
      {pastEvents && pastEvents.length > 0 && pastEvents.map((item)=>(
          <Div key={item.key}>
            <Post 
              url={Base64.encode(item._id)}
              src={item.banner} 
              alt={item.title} 
              date={item.date}
              title={item.title}
            />
          </Div>
        ))}
    </Slider>
  )
}
