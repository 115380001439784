import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function PageHeadingEvents({ title, bgSrc, pageLinkText, videoSrc }) {
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      style={{
        position: 'relative',
        backgroundImage: videoSrc ? 'none' : `url(${bgSrc})`,
        zIndex: 1,
      }}
    >
      {videoSrc && (
        <video
          src={videoSrc}
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
            opacity: 0.4,
          }}
        />
      )}
      <Div className="container" style={{ zIndex: 2, opacity: 1 }}>
        <Div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-white_color">{title}</h1>
          <ol className="breadcrumb text-uppercase">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">{pageLinkText}</li>
          </ol>
        </Div>
      </Div>
    </Div>
  );
}