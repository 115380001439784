import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Base64 } from 'js-base64';

export default function Events() {
  pageTitle('Events');
  const [active, setActive] = useState('all');

  const [events, setEvents] = useState([]);



  const categoryMenu = [
    {title: 'Upcoming', id: 1},
    {title: 'Completed', id: 2}
  ];
  
  useEffect(() => {
  fetch('https://mhangsa.glitch.me/api/events')
    .then(response => response.json())
    .then(data => setEvents(data.reverse()))
    .catch(error => console.error(error));
  window.scrollTo(0, 0);
}, []);


  return (
    <>
      <PageHeading
        title="Events"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Events"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Our Events" subtitle="Mhangsa Events" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu && categoryMenu.length > 0 && categoryMenu.map((item, index) => (
                <li
                  className={active === item.title ? 'active' : ''}
                  key={item.id}
                >
                  <span style={{textTransform: 'capitalize'}} onClick={() => setActive(item.title)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
        {events && events.length > 0 && events.map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.status)
                  ? 'd-none'
                  : ''
              }`}
              key={item.id}
            >
               <Portfolio
                title={item.title}
                subtitle={"See Details"}
                href={`/events/${Base64.encode(item._id)}`}
                src={item.banner}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

     
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="mhangsa.creations@gmail.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
