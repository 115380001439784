import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import Card from '../Card';
// import MovingText2 from '../MovingText/MovingText2';
import Portfolio from '../Portfolio';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import MovingText from '../MovingText';
import PostSlider from '../Slider/PostSlider';
import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';


import { Base64 } from 'js-base64';

export default function Home() {
  pageTitle('Home');


  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);

  const [upcomingEvents, setUpcomingEvents] = useState([]);
 

  const portfolioData = [
    {
      title: 'PUBG Tournament',
      subtitle: 'See Details',
      // href: '/portfolio/portfolio-details',
      src: '/images/portfolio_4.jpeg',
      category: 'artists',
    },
    {
      title: 'Valorant Tournament',
      subtitle: 'See Details',
      // href: '/portfolio/portfolio-details',
      src: '/images/portfolio_6.jpeg',
      category: 'tournament',
    },
    {
      title: 'Corporate Events',
      subtitle: 'See Details',
      // href: '/portfolio/portfolio-details',
      src: '/images/portfolio_8.jpeg',
      category: 'artists',
    },
    {
      title: 'Concert',
      subtitle: 'See Details',
      // href: '/portfolio/portfolio-details',
      src: '/images/portfolio_6.jpeg',
      category: 'tournament', 
    },
  ];

  const categoryMenu = [
  
  ];

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/mhangsa.creations/',
    },
    {
      name: 'Youtube',
      links: 'https://www.youtube.com/@mhangsa8028',
    }
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Events',
      factNumber: '5',
    },
    {
      title: 'Tournaments',
      factNumber: '10',
    },
    {
      title: 'Community',
      factNumber: '5',
    },
    {
      title: 'Partners',
      factNumber: '10',
    },
  ];

  

  useEffect(() => {
    fetch('https://mhangsa.glitch.me/api/events')
      .then(response => response.json())
      .then(data => {
        const upcomingEvents = data.filter(event => event.status === 'Upcoming');
        setUpcomingEvents(upcomingEvents);
      })
      .catch(error => console.error(error));
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Elevating events <br/>to new heights"
        subtitle="Transforming ordinary events into extraordinary experiences through creativity, innovation and attention to detail."
        btnText="Get a Quote"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Mhangsa events"
          subtitle="Mhangsa Events creates unforgettable experiences for any occasion with our experienced and creative team. We take pride in our attention to detail, exceptional customer service, and tailored approach. Let us exceed your expectations and take care of every aspect of your event. Thank you for considering Mhangsa Events."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Mhangsa events"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div> */}
      {/* End Service Section */}

       {/* Start Moving Text Section */}
      
      <Spacing lg="20" md="10" />
      {/* <MovingText2 reverseDirection data={serviceData2} /> */}
      {/* End Moving Text Section */}

      <Div id="service" style={{marginTop: '100px'}}>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Musical Events"
                    // link="/service/service-details"
                    src="/images/service_1.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Corporate Events"
                    src="/images/service_2.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Esports Tournament"
                    src="/images/service_3.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Adventure Events"
                    src="/images/service_4.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
      

      {/* Start Portfolio Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Upcoming events" subtitle="What's happening?" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {upcomingEvents && upcomingEvents.length > 0 && upcomingEvents.map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={item._id}
            >
              <Portfolio
                title={item.title}
                subtitle={"See Details"}
                href={`/events/${Base64.encode(item._id)}`}
                src={item.banner}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      
      {/* End Portfolio Section */}

      

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Mhangsa delivers successful events with our agile process, adapting to changes for exceptional outcomes.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=true&loop=true&url=https%3A%2F%2Fres.cloudinary.com%2Fdvus66wy1%2Fvideo%2Fupload%2Fv1678430766%2FVideo%2520Banner%2FKurukshetra_qvfium.mp4&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
          bgUrl="/images/video_bg.png"
        />
      </Div>
      {/* End Video Block Section */}


      {/* Start Blog Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Our past events"
                subtitle="Events"
                btnText="View More"
                btnLink="/events"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our past events" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      {/* <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="We can make something cool together!<br />Do you have any ideas?"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/hero_bg.jpeg"
          // bgSrc="/images/cta_bg.jpeg"
        />
     
      </Div>
      {/* End CTA Section */}
    </>
  );
}
